const ListItem = ({ logo, title, subtitle, badgeText, description, withMarginBottom = true }) => {
    return (
        <div className='list-item'>
            <div className={`list-item-row ${withMarginBottom ? '' : 'mb-0'}`}>
                {logo && <img className='list-item-logo' src={logo} alt='Logo' />}
                <div className='list-item-details-container'>
                    {title && <div className='list-item-title'>{title}</div>}
                    {subtitle && <div className='list-item-subtitle'>{subtitle}</div>}
                    {badgeText && <div className='badge bg-secondary text-secondary my-1 p-1 p-xl-2'>{badgeText}</div>}
                </div>
            </div>
            {description && (
                <ul>
                    {Object.keys(description).map((key) => (
                        <li className='list-item-point' key={key}>
                            {description[key]}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ListItem;
