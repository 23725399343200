import mix_and_drink_thumbnail from '../../../assets/images/projects/mix-and-drink/thumbnail.jpg';
import mix_and_drink_1 from '../../../assets/images/projects/mix-and-drink/1.jpg';
import mix_and_drink_2 from '../../../assets/images/projects/mix-and-drink/2.jpg';
import mix_and_drink_3 from '../../../assets/images/projects/mix-and-drink/3.jpg';
import mix_and_drink_4 from '../../../assets/images/projects/mix-and-drink/4.jpg';
import mix_and_drink_5 from '../../../assets/images/projects/mix-and-drink/5.jpg';
import mix_and_drink_6 from '../../../assets/images/projects/mix-and-drink/6.jpg';
import mix_and_drink_7 from '../../../assets/images/projects/mix-and-drink/7.jpg';
import mix_and_drink_8 from '../../../assets/images/projects/mix-and-drink/8.jpg';
import mix_and_drink_9 from '../../../assets/images/projects/mix-and-drink/9.jpg';
import mix_and_drink_10 from '../../../assets/images/projects/mix-and-drink/10.jpg';
import store_app_thumbnail from '../../../assets/images/projects/store-app/thumbnail.jpg';
import store_app_1 from '../../../assets/images/projects/store-app/1.jpg';
import store_app_2 from '../../../assets/images/projects/store-app/2.jpg';
import store_app_3 from '../../../assets/images/projects/store-app/3.jpg';
import store_app_4 from '../../../assets/images/projects/store-app/4.jpg';
import store_app_5 from '../../../assets/images/projects/store-app/5.jpg';
import store_app_6 from '../../../assets/images/projects/store-app/6.jpg';
// import worldwide_weather_thumbnail from '../../../assets/images/projects/worldwide-weather/thumbnail.jpg';
// import worldwide_weather_1 from '../../../assets/images/projects/worldwide-weather/1.jpg';
// import worldwide_weather_2 from '../../../assets/images/projects/worldwide-weather/2.jpg';
// import worldwide_weather_3 from '../../../assets/images/projects/worldwide-weather/3.jpg';
// import worldwide_weather_4 from '../../../assets/images/projects/worldwide-weather/4.jpg';

const projects = [
    {
        _id: '1',
        title: 'projects-item-1-title',
        subtitle: 'projects-item-1-subtitle',
        description: 'projects-item-1-description',
        isMobileApp: true,
        appStoreLink: 'https://apps.apple.com/us/app/mix-drink-cocktail-recipes/id1565193102',
        googlePlayLink: 'https://play.google.com/store/apps/details?id=com.the_cocktail_book',
        thumbnail: mix_and_drink_thumbnail,
        images: [
            {
                original: mix_and_drink_1,
                thumbnail: mix_and_drink_1,
            },
            {
                original: mix_and_drink_2,
                thumbnail: mix_and_drink_2,
            },
            {
                original: mix_and_drink_3,
                thumbnail: mix_and_drink_3,
            },
            {
                original: mix_and_drink_4,
                thumbnail: mix_and_drink_4,
            },
            {
                original: mix_and_drink_5,
                thumbnail: mix_and_drink_5,
            },
            {
                original: mix_and_drink_6,
                thumbnail: mix_and_drink_6,
            },
            {
                original: mix_and_drink_7,
                thumbnail: mix_and_drink_7,
            },
            {
                original: mix_and_drink_8,
                thumbnail: mix_and_drink_8,
            },
            {
                original: mix_and_drink_9,
                thumbnail: mix_and_drink_9,
            },
            {
                original: mix_and_drink_10,
                thumbnail: mix_and_drink_10,
            },
        ],
    },
    {
        _id: '2',
        title: 'projects-item-2-title',
        subtitle: 'projects-item-2-subtitle',
        description: 'projects-item-2-description',
        isMobileApp: true,
        thumbnail: store_app_thumbnail,
        inProgress: true,
        images: [
            {
                original: store_app_1,
                thumbnail: store_app_1,
            },
            {
                original: store_app_2,
                thumbnail: store_app_2,
            },
            {
                original: store_app_3,
                thumbnail: store_app_3,
            },
            {
                original: store_app_4,
                thumbnail: store_app_4,
            },
            {
                original: store_app_5,
                thumbnail: store_app_5,
            },
            {
                original: store_app_6,
                thumbnail: store_app_6,
            },
        ],
    },
    // {
    //     _id: '3',
    //     title: 'projects-item-3-title',
    //     subtitle: 'projects-item-3-subtitle',
    //     description: 'projects-item-3-description',
    //     isMobileApp: true,
    //     thumbnail: worldwide_weather_thumbnail,
    //     inProgress: false,
    //     images: [
    //         {
    //             original: worldwide_weather_1,
    //             thumbnail: worldwide_weather_1,
    //         },
    //         {
    //             original: worldwide_weather_2,
    //             thumbnail: worldwide_weather_2,
    //         },
    //         {
    //             original: worldwide_weather_3,
    //             thumbnail: worldwide_weather_3,
    //         },
    //         {
    //             original: worldwide_weather_4,
    //             thumbnail: worldwide_weather_4,
    //         },
    //     ],
    // },
];

export default projects;
