import { useTranslation } from 'react-i18next';
import services from './data';
import Card from '../../common/card';
import useInView from '../../../hooks/useInView';

const Services = () => {
    const { t, i18n } = useTranslation();
    const isRtl = i18n.language === 'he';
    const [ref, isInView] = useInView();

    const renderServices = () => {
        return services.map((service, index) => (
            <Card
                key={service._id}
                // isLastItem={index === services.length - 1}
                icon={service.icon}
                name={t(service.name)}
                description={t(service.description)}
                classes={isInView ? 'fade-up' : ''}
            />
        ));
    };

    return (
        <section id='services'>
            <div className='container-xl' ref={ref}>
                <div className='text-center'>
                    <h3 className='section-title'>
                        <span className={`${isRtl ? 'text-primary' : 'text-secondary'}`}>
                            {t('services-title.part1')}
                        </span>
                        <span className={`${isRtl ? 'text-secondary' : 'text-primary'}`}>
                            {t('services-title.part2')}
                        </span>
                    </h3>
                </div>
                <div className='row text-center'>{renderServices()}</div>
            </div>
        </section>
    );
};

export default Services;
